export const NoTournamentData = ({
  isLiveScored,
  customText,
}: {
  isLiveScored?: boolean;
  customText?: string;
}) => (
  <div className="text-sm lg:text-base text-secondary p-4 w-full text-center">
    {customText ?? (
      <>
        <h3 className="text-base text-gray-950 mb-2">
          {isLiveScored
            ? 'This tournament is using ScoreboardLive.'
            : 'This tournament is not using ScoreboardLive.'}
        </h3>
        <p className="text-sm">
          {isLiveScored ? (
            <>Results will be appear once the tournament commences play.</>
          ) : (
            <>Results will be appear once the tournament host publishes scores.</>
          )}
        </p>
      </>
    )}
  </div>
);
