import { FC } from 'react';
import { TriangleDownIcon, TriangleUpIcon } from '@radix-ui/react-icons';

type PositionDeltaDisplayProps = {
  change: number;
};

const renderChange = (change: number) => {
  if (change > 0) {
    return (
      <span className="flex text-green-600 items-center justify-end">
        {change} <TriangleUpIcon />
      </span>
    );
  } else if (change < 0) {
    return (
      <span className="flex text-red-600 items-center justify-end">
        {Math.abs(change)} <TriangleDownIcon />
      </span>
    );
  } else {
    return <span className="text-secondary">-</span>;
  }
};

export const PositionDeltaDisplay: FC<PositionDeltaDisplayProps> = ({ change }) => (
  <span className="inline-block w-8 text-center">{renderChange(change)}</span>
);
