'use client';

import * as React from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { cn } from '../../utils/cn';

const Tabs = TabsPrimitive.Root;

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> & { showTrack?: boolean }
>(({ className, children, showTrack = true, ...props }, _ref) => {
  const list = React.useRef<HTMLDivElement>();
  const track = React.useRef<HTMLDivElement>(null);
  const [trackStyle, setTrackStyle] = React.useState<React.CSSProperties>({
    width: 8,
  });
  const ref = React.useCallback(
    (el: HTMLDivElement) => {
      list.current = el;
      if (typeof _ref === 'function') {
        _ref(el);
      } else if (_ref) {
        _ref.current = el;
      }
    },
    [_ref]
  );
  const handleUpdate = React.useCallback((activeElement: Element | null | undefined) => {
    if (activeElement && list.current) {
      const listBounds = list.current.getBoundingClientRect();
      const bounds = activeElement.getBoundingClientRect();

      setTrackStyle({
        transform: `translateX(${bounds.left - listBounds.left}px)`,
        width: bounds.width,
      });
    } else {
      setTrackStyle({});
    }
  }, []);

  React.useEffect(() => {
    const activeElement = list.current?.querySelector('.tab-trigger[data-state="active"]');
    handleUpdate(activeElement);
  }, []);

  return (
    <TabsPrimitive.List
      ref={ref}
      className={cn('mb-6 relative', className)}
      {...props}
      onClick={(e) => {
        if (e.target instanceof Element && e.target.classList.contains('tab-trigger')) {
          handleUpdate(e.target);
        }
      }}
    >
      {showTrack && (
        <div className="absolute bottom-0 left-0 right-0 h-2 bg-zinc-300 dark:bg-coolgray-mid w-full rounded-full" />
      )}
      <div className="inline-flex items-center justify-center rounded-lg">{children}</div>
      {showTrack && (
        <div
          ref={track}
          className="absolute left-0 bottom-0 h-2 bg-clippd-pink rounded-full transition-all duration-200 ease-out"
          style={trackStyle}
        />
      )}
    </TabsPrimitive.List>
  );
});
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => {
  return (
    <TabsPrimitive.Trigger
      ref={ref}
      className={cn(
        'tab-trigger inline-flex items-center justify-center whitespace-nowrap rounded-md px-8 pt-1 pb-4 font-medium ring-offset-white transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-primary dark:text-white dark:ring-offset-zinc-950 dark:focus-visible:ring-zinc-300 text-sm lg:text-base data-[state=active]:text-clippd-pink hover:text-clippd-pink',
        className
      )}
      {...props}
    />
  );
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2 dark:ring-offset-zinc-950 dark:focus-visible:ring-zinc-300',
      className
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
