'use client';

import { NoTournamentData } from '@/components/placeholder/no-tournament-data';
import {
  ElasticRoundAnalyticsWithAveragePoints,
  RoundAnalytics,
  TournamentEventTypeEnum,
} from '@spikemark/rest-api';
import { useFetch } from '@spikemark/shared-hooks';
import { withErrorBoundary } from '@spikemark/ui/shell/error-container';
import { type MatchplayLeaderboardItem } from '@spikemark/ui/types/leaderboard';
import classNames from 'classnames';
import { FC } from 'react';
import { PreviewLeaderboardProps } from './preview-leaderboard';
import {
  PreviewMatchplayLeaderboardGrid,
  PreviewStrokeplayLeaderboardGrid,
} from './preview-leaderboard-grid';
import { isLiveTournament } from '@/util/tournament';

export const PreviewLeaderboardClient: FC<PreviewLeaderboardProps> = withErrorBoundary(
  ({ type, tournament, isInView }) => {
    const format = tournament.eventType;
    const tournamentId = tournament.tournamentId;
    const isMatchplay = format !== TournamentEventTypeEnum.StrokePlay;

    const queryUrl = isMatchplay
      ? `/api/tournaments/${tournamentId}/leaderboards/matchplay`
      : `/api/tournaments/${tournamentId}/leaderboards/${type}`;

    const { data, isLoading, error } = useFetch<{
      tournamentId: string;
      results: MatchplayLeaderboardItem[] | RoundAnalytics[];
    }>(queryUrl, 'GET', {
      skip: !isInView,
      reset: false,
    });

    const content = (function () {
      if (error || !data?.results.length) {
        return (
          <div className="py-5">
            <NoTournamentData isLiveScored={isLiveTournament(tournament)} />
          </div>
        );
      }

      if (isMatchplay && data?.results) {
        return (
          <PreviewMatchplayLeaderboardGrid
            tournament={tournament}
            data={data.results as MatchplayLeaderboardItem[]}
          />
        );
      }

      return (
        <PreviewStrokeplayLeaderboardGrid
          tournament={tournament}
          data={(data?.results as ElasticRoundAnalyticsWithAveragePoints[]) ?? []}
          type={type}
        />
      );
    })();

    const showOverlay = isLoading || !isInView;

    return (
      <div className="lg:min-h-[370px] relative">
        <div
          className={classNames({
            invisible: showOverlay,
          })}
        >
          {content}
        </div>
        <PreviewLeaderboardLoadingOverlay show={showOverlay} />
      </div>
    );
  }
);

const PreviewLeaderboardLoadingOverlay: FC<{ show: boolean }> = ({ show }) => (
  <div
    className={classNames(
      'absolute top-0 left-0 right-0 bottom-0 pointer-events-none animate-pulse'
    )}
  >
    <div
      className={classNames(
        'pt-2 border-b border-gray-300 dark:border-coolgray-mid h-6 transition-all duration-300 ease-in-out',
        {
          'opacity-0': !show,
        }
      )}
    >
      <div className="bg-white h-3"></div>
    </div>
    {Array.apply(null, Array(7)).map((_, i) => (
      <div
        key={i}
        className={classNames(
          'py-2 border-b border-gray-200 dark:border-coolgray-mid h-12 transition-all ease-in-out origin-right',
          {
            'scale-x-0 opacity-50': !show,
          }
        )}
        style={{
          transitionDuration: `${50 + (7 - i) * 30}ms`,
        }}
      >
        <div className="bg-gray-100 dark:bg-coolgray-light h-8"></div>
      </div>
    ))}
  </div>
);
