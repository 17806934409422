'use client';

import { FC, useEffect } from 'react';
import { Button } from '../components/ui/button';
import { logError } from './error-log';
import { ErrorBoundary } from 'next/dist/client/components/error-boundary';

export const ErrorContainer: FC<{
  error: Error & { digest?: string };
  reset: () => void;
}> = ({ error, reset }) => {
  useEffect(() => {
    logError({
      message: error.message,
      digest: error.digest,
    });
  }, [error]);

  return (
    <div className="p-4 lg:p-6">
      <h2 className="text-lg font-light">Something went wrong!</h2>
      <p className="mb-4">{String(error)}</p>
      {reset ? <Button onClick={() => reset()}>Try again</Button> : null}
    </div>
  );
};

export function withErrorBoundary<TProps extends {}>(Component: FC<TProps>): FC<TProps> {
  return (props: TProps) => (
    <ErrorBoundary errorComponent={ErrorContainer}>
      <Component {...props} />
    </ErrorBoundary>
  );
}
